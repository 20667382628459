.MenuContainer {
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.MenuContainer > * {
  width: 100%;
}
.MenuContainer h2 {
  padding: 6vh 0 2vh 0;
  font-weight: 700;
}

.menu {
  font-weight: 300;
  position: absolute;
  width: 15%;
  background-color: black;
  color: white;
  height: 90%;
  border: 1px solid black;
  border-radius: 2vh 0 0 2vh;
  max-width: 0;
  overflow: hidden;
  z-index: 1;
  transition: max-width 0.3s ease-in-out;
}
.menu.active {
  max-width: 30vw;
}

.menu a {
  text-transform: capitalize;
  text-decoration: none;
  font-weight: bold;
  color: white;
  width: 100%;
  padding: 2vh;
}

.menu a:hover {
  background-color: white;
  color: black;
  width: 100%;
}
