.editTableRow {
  flex-direction: row !important;
  justify-content: space-between;
  width: 100% !important;
  border-bottom: solid 1px black;
  overflow-y: visible;
}

.editTableRow :first-child {
  border: none;
}

.editTableCell {
  justify-content: center;
  border-left: solid 1px black;
  /* padding: 0.5%; */
  word-wrap: break-word;
  min-height: 4.5vh;
  height: 100%;
  flex-direction: row !important;
  justify-content: space-around;
  align-items: center !important;
}
.editTableCell > div {
  /* padding: 0.5%; */
  width: auto;
}

.editTableHeader {
  color: white;
  background-color: midnightblue;
  font-weight: bold;
  border-left: solid 1px white;
  text-transform: capitalize;
  padding: 0.5%;
}
.editTable {
  width: 100% !important;
  justify-content: flex-start;
  overflow-y: scroll;
}
.editTable textarea {
  font-size: medium;
  border: none;
  border-radius: 0;
  width: 100%;
  /* height: 100%; */
  margin: 0;
  padding: 1%;
  word-break: normal;
  height: 4.5vh;
}
.QuoteList {
  height: 9vh !important;
}
.foot {
  padding-top: 2%;
}
.TableFuncButton {
  border-radius: 25%;
  background-color: midnightblue;
  color: white;
  width: 20px !important;
  height: 20px !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
}

.TableFuncButton:hover {
  background-color: #4596cc;
}
.Add {
  justify-content: flex-start;
  padding: 1%;
}
.Add div {
  user-select: none;
  cursor: pointer;
  margin-right: 5%;
}
.Add:hover .TableFuncButton {
  background-color: #4596cc;
}
