.App {
  width: 100vw;
  height: 100vh;
  font-family: "Inria Sans", sans-serif;
  font-size: 1vw;
  background: #071b4d;
  background: -moz-linear-gradient(
    -45deg,
    #071b4d 0%,
    #071b4d 50%,
    #4596cc 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #071b4d 0%,
    #071b4d 50%,
    #4596cc 100%
  );
  background: linear-gradient(135deg, #071b4d 0%, #071b4d 50%, #4596cc 100%);
  /* 
  display: flex;
  justify-content: center;
  align-items: center; */
}
div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}
.scrollBox {
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
}
.containter {
  width: 90%;
  height: 90%;
  background-color: whitesmoke;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.hover {
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);
}

.card {
  border-radius: 2vh;
}

.content {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.row {
  width: 100%;
  justify-content: space-between;
}
.sub {
  width: 70%;
}

.amplify-tabs {
  display: none !important;
}
