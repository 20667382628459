.popupOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  left: 0;
}
.popupContianer {
  border-radius: 1vh;
  padding: 2vh 2vw;
  height: 50vh;
  width: 50vw;
  background-color: whitesmoke;
  flex-direction: column;
  justify-content: space-around;
  padding: 2%;
}
.popupContent {
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  padding: 2%;
  /* overflow: scroll; */
}

.popupContent div {
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
}
.popupContent textarea {
  font-family: "Inria Sans", sans-serif;
  font-size: 2vh;
  width: 90%;
  height: 8vh;
}

.popupNav {
  width: 100%;
  justify-content: space-around;
}
.popUpSmall {
  font-size: small;
}
