.questions {
  max-height: 50vh;
  width: 80%;
}
.qTable {
  width: 80%;
}
.qTableRow {
  justify-content: space-evenly;
  /* border-bottom: 1px black solid; */
  width: 100%;
}
.qTableItem {
  padding: 1vh;
}
.shortItem {
  width: 5%;
  min-width: 5%;
}
.longItem {
  width: 30%;
  min-width: 30%;
}
.border {
  border: 1px solid black;
}
.header {
  color: white;
  background-color: midnightblue;
  font-weight: bold;
}
