.editArea {
  max-height: 40vh;
  width: 80%;
}
.editArea thead {
  display: none;
}
.head {
  width: 80%;
}
tbody {
  width: 100%;
}
tbody {
  max-height: 40vh;
}
table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
th {
  background-color: midnightblue;
  color: white;
  border-left: 1px solid white;
  text-align: left;
}
td {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
tr {
  width: 100%;
}
tr:hover {
  background-color: rgb(187, 187, 187);
}

.CVbuttons {
  padding: 1vh 0;
  width: 70%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.CVbuttons .divButton {
  padding: 1vh 1vw !important;
  margin: 0.3vw;
  /* width: 12vw; */
}

input {
  border-radius: 5vw;
  margin-bottom: 0.5vh;
  padding: 0.5vh;
}
.divButton {
  user-select: none;
  cursor: pointer;
  background-color: midnightblue;
  color: white;
  padding: 0.5vh 0.5vw;
  border-radius: 2vw;
}
.divButton:hover {
  background-color: #4596cc;
}

.disabled {
  background-color: gray;
  pointer-events: none;
}

td,
th {
  padding: 1vh;
}
.ID {
  width: 5%;
  text-align: right;
}
.value {
  width: 7%;
}
.description {
  width: 38%;
}
.dateSet {
  width: 10%;
}
.previous {
  width: 25%;
  text-align: center;
}
.edit {
  text-align: center;
  width: 15%;
}
.edit .divButton {
  width: 45%;
}
.edit input {
  width: 80%;
}
