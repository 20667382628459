*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --bar-width: calc((3vh + 2vw) / 2);
  --bar-height: calc((0.5vh + 0.2vw) / 2);
  --gap: calc(var(--bar-height) * 1.5);

  --fgc: black;
  --fgch: white;
  --bgc: #4596cc;

  --timing: 0.3s ease-in-out;

  --hambuger-height: calc(var(--bar-height) * 3 + var(--gap) * 2);

  --circleRad: calc(var(--bar-width) * 1.5);
}

.menubutton {
  --x-width: calc(var(--hambuger-height) * 1.41421356237);

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  width: max-content;
  /* position: absolute;
  top: var(--hamburger-margin);
  left: var(--hamburger-margin); */
  z-index: 2;
  cursor: pointer;
}
.menubutton input,
.menubutton::before,
.menubutton::after {
  content: "";
  width: var(--bar-width);
  height: var(--bar-height);
  background-color: var(--fgc);
  border-radius: 100vw;
  transform-origin: left center;
  transition: opacity var(--timing), width var(--timing), rotate var(--timing),
    translate var(--timing);
}
.menubutton input {
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}

.active::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: 15% calc(var(--bar-height) / -2);
  /* calc((var(--bar-width)-var(--x-width)) / 8)
    calc(var(--bar-height) / -2); */
}

.active::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: 15% calc(var(--bar-height) / 2);
  /* transform: translateX(calc((var(--bar-width)-var(--x-width)) / 8));
  transform: translateY(calc(var(--bar-height) / -4)); */
}
/* 
.menubutton:has(input:checked)::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: calc((var(--bar-width)-var(--x-width)) / 8)
    calc(var(--bar-height) / -2);
}

.menubutton:has(input:checked)::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: calc((var(--bar-width)-var(--x-width)) / 8)
    calc(var(--bar-height) / 2);
} */

.menubutton.active input {
  opacity: 0;
  width: 0;
}
/* .menubutton input:checked {
  opacity: 0;
  width: 0;
} */

.circle {
  position: absolute;
  top: 7vh;
  left: 7vw;
  background: var(--bgc);
  margin: 1vw;
  border-radius: 50%;
  width: var(--circleRad);
  height: var(--circleRad);
  transition: scale var(--timing);
  z-index: 2;
}

.circle:hover {
  scale: 1.3;
}

.circle:hover input,
.circle:hover .menubutton::after,
.circle:hover .menubutton::before {
  background-color: var(--fgch);
}
