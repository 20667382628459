.SCShort {
  width: 5%;
}
.SCMid {
  width: 30%;
}
.SCLong {
  width: 65%;
  padding: 1vh;
}

.SCTable {
  border: solid 1px black;
  height: 50vh;
  width: 80%;
}
.SCRow {
  padding: 0.5vh 0 0.5vh 0;
  width: 100%;
  border-bottom: black 1px solid;
}
.SCHeader {
  /* border-bottom: black 1px solid; */
  font-weight: bold;
  background-color: midnightblue;
  color: white;
}
.SCTable textarea {
  width: 100%;
  margin: 1vh 0;
}

.SCOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.SCPopup {
  background-color: white;
  width: 50%;
  height: 50%;
}
