.WLContainer {
  flex-direction: column;
  border-radius: 1vw;
  background-color: white;
  padding: 1vh 1vw;
  margin: 1vw;

  -webkit-box-shadow: 5px 5px 3px 0px #000000,
    5px 5px 20px 0.3vw rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 3px 0px #000000, 5px 5px 20px 0.3vw rgba(0, 0, 0, 0.2);
}
.WLbuttons {
  width: 100%;
  justify-content: space-between;
  padding: 1vh;
}
#Domain,
#CompanyName {
  width: 70%;
  height: 5vh;
}
.dataLabel {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.logoContainer .dataLabel span {
  flex-wrap: wrap;
  width: 30%;
}

textarea {
  resize: none;
  height: 10vh;
  word-break: break-all;
}

.logoCap {
  flex-direction: column;
  margin: 0.8vw;
}

.logoContainer {
  align-items: flex-start;
}

.URL {
  font-size: small;
  font-weight: 300;
  word-break: break-all;
}
.squeeze {
  width: 6vw;
}
.logoItem {
  border: 1px solid midnightblue;
  width: 5vw;
  height: 5vw;
}
.logoItem img {
  width: 4.5vw;
}
.colorContainer {
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
}
.colorContainer span {
  width: 40%;
}
.colorItem {
  font-size: smaller;
  padding: 0.1vw;
  text-transform: capitalize;
}
.color {
  margin-left: 0.1vw;
  width: 1vw;
  height: 1vw;
  -webkit-box-shadow: 0px 0px 0px 1px #000000, 0px 0px 0px 1px #ffffff;
  box-shadow: 0px 0px 0px 1px #000000, 0px 0px 0px 1px #ffffff;
}
