h1 {
  margin: 2vw;
}
.WhiteLabelPage {
  flex-direction: column;
}
.WhiteLabelContainer {
  overflow-y: scroll;
  flex-wrap: wrap;
}
.WhiteLabelContainer {
overflow-y: scroll;
flex-wrap: wrap;
  
}